.contact-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 12rem;
  background-color: #EEEEEE;
}
.top-border-radius{
  border-radius: 80px 80px 0 0;
}
.contact-text {
  flex: 1;
  padding: 30px;
}

.contact-text h2 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #10C7AF;
  text-align: left;
}

.contact-text p {
  font-size: 18px;
  margin-bottom: 20px;
}

.contact-text input, .contact-text textarea {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  outline: none;
}

.contact-text textarea {
  height: 120px;
  resize: none;
}


.contact-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}
.contact-icons{
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: flex-start;
}
.contact-image img {
  max-width: 60%;
  height: auto;
}
.contact-icons img{
margin-top: 10px;
}



@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    padding: 30px;
    border-radius: 80px 80px 0 0;
  }

  .contact-text, .contact-image {
    flex: none;
    width: 100%;
  }

  .contact-text {
    padding: 20px 0;
  }

  .contact-image {
    order: -1; 
    margin-bottom: 30px;
  }

  .contact-image img {
    max-width: 80%; 
  }

  .contact-icons {
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  }

  .contact-icons img {
    margin: 0 10px; 
  }
}
