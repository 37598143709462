.about1-us {
    width: 100%;
    max-width:800px;
    text-align: start; 
    margin: 50px auto;
    font-family: Arial, sans-serif;
    padding: 0 15px; 
}



.section {
    margin-bottom: 40px;
}

h2 {
    font-size: 24px;
    color: #444;
    margin-bottom: 20px;
}

p {
    font-size: 18px;
    line-height: 1.5;
    width: 100%;
    color: #555;
    text-align: start; 
}

/* If you have additional styles, branding colors, or fonts, you can add them here */
