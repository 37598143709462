.work-with-section {
    background-color: #f0f0f0;
    height: 100%;
  }
  .work-with-container {
    margin: 0rem 9rem;
    padding: 4rem;
  }
  .work-with-header {
    margin: 0rem 10rem;
  }
  .wws-heading{
    font-size: 4rem !important;
  }
  .git-heading{
    font-size: 2.5rem !important;
  }
  
  .messageus-link{
    color: #00b7bd;
    text-decoration: none;
  }
  .messageus-link:hover{
    color: #059a9f;
  }

  
  @media (max-width: 768px) {
    .work-with-container {
      margin: 0rem 1rem;
          padding: 1rem;
    }
    .work-with-header {
      margin: 0;
    }
    .wws-heading{
      font-size: 3.8rem !important;
    }
    
  }
  
  @media (min-width: 768px) and (max-width: 991.98px){
    .work-with-container {
      margin: 0rem 2rem;
      padding: 2rem;
    }
    .work-with-header {
      margin: 0;
    }
  }
  