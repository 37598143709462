
.service-components-section {
  height: 100%;
}
.service-components-container {
  padding: 5rem 12rem;
}
.service-components-box {
  width: 100%;
  height: 100%;
  opacity: 1;
  border-radius: 29px;
  color: #000;
  display: grid;
  grid-template-columns: 1.2fr 1fr;
}
.service-components-image-container .service-components-image {
  width: 65%;
  height: auto;
}
.service-components-image-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-components-paragraph {
  margin: 0;
  color: #000 !important;
}

.service-components-text .service-components-heading {
  text-align: left;
  font-weight: bold;
  color: #01bfc59d !important;
}
.service-components-btn .btn {
  margin: 0 0.3rem;
  width: 30%;
}

@media (max-width: 768px) {
  .service-components-container {
    padding: 1rem 2rem;
  }
  .service-components-box {
    display: flex;
    flex-direction: column;
  }
  .service-components-image-container{
    justify-content: center;
    display: flex;
    align-items: center;
    padding-top: 2rem;
  }
  .service-components-image-container .service-components-image {
    height: auto;
  }
  .service-components-paragraph {
    margin: 1rem 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .service-components-container {
    padding: 4rem 4rem;
  }
  .service-components-image-container .service-components-image {
    width: 75%;
    height: auto;
  }
  .service-components-section {
    height: 75% !important;
  }
  .service-components-box {
    height: 75% !important;
  }

  .service-components-text .service-components-heading {
    padding-right: 0rem;
  }
  .service-components-btn .btn {
    width: 35%;
  }
  .service-components-text {
    font-size: 1rem;
  }
  .service-components-text .service-components-heading {
    font-size: 1.8rem;
  }
}






















