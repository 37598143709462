
.services-section {
    background-color: #FFFFFF;
    height: 100%;
  }
  .services-container {
    margin: 0rem 9rem;
    padding: 6.5rem 4rem;
  }
  .services-header {
    margin: 0 10rem 2rem 10rem;
  }
.services-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.service-card img {
    width: 40%;  
    height: 60%;
    display: block;  
    margin: 0 auto; 
}

.service-text {
    font-size: 1.3rem; 
    color: #10C7AF;
    font-weight: 600; 
    text-align: center !important;
    margin: 0;
}



.service-card {
    padding: 3rem;
    border-radius: 30px;
    overflow: hidden;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 30px #00000015;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 35vh;
    flex-direction: column;
}
.service-card:hover{
    background-color: #fbfbfb;
}

@media (max-width: 768px) {
    .services-container {
      margin: 0rem 1rem;
          padding: 1rem;
    }
    .services-header {
      margin: 0;
    }
    .services-cards {
        grid-template-columns: repeat(2, 1fr);
        gap: .7rem;
    }
    .service-card {
        padding: 2rem .8rem;
        border-radius: 20px;
        height: 19vh;
    }
    .service-card img {
        width: 50%;  
        height: 100%;
        display: block;  
        margin: .3rem auto; 
    }
    .service-text{
        font-size: 0.8rem;
        padding: 0rem;
    }
}

@media (min-width: 768px) and (max-width: 991.98px){
    .services-container {
      margin: 0rem 2rem;
      padding: 2rem;
    }
    .services-header {
      margin: 0;
    }
    .service-card {
        padding: 2rem .8rem;
        border-radius: 20px;
        height: 27vh;
    }
    .service-card img {
        width: 50%;  
        height: 100%;
        display: block;  
        margin: .3rem auto; 
    }
    .service-text{
        font-size: 1rem;
        padding: 0rem;
    }
    .services-cards {
        gap: 1rem;
    }
}


