.work-section {
  background-color: #ffff;
  height: 100%;
}
.work-section-gray{
  background-color: #eeeeee;
  height: 100%;
}
.work-container {
  margin: 0rem 9rem;
  padding: 4rem;
}
.work-header {
  margin: 0rem 10rem;
  padding-top: 2rem;
}

.template-thumbnails-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  gap: 1rem;
  padding: 1rem 0;
}
.template-name{
  font-size: 1rem !important;
}
.template-thumbnail-items {
  width: 100%;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0px 2px 40px #00000040;
  height: 35vh !important;
}
.template-thumbnail-items:hover {
  transform: scale(1.2);
}
.app-template-thumbnail-items {
  width: 100%;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0px 2px 40px #00000040;
  height: 65vh;
}
.app-template-thumbnail-items:hover {
  transform: scale(1.2);
}
.img-thumbnail {
  padding: 0 !important;
  border-radius: 29px !important;
  cursor: pointer;
}
.work-view-more-section {
  padding: 2rem 0;
}

body {
  font-family: Arial, sans-serif;
}

.btn-group .btn {
  border-radius: 20px;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.slider-container .tabs{
  margin: 1rem 2rem;
  width: 25% !important;
}

@media (max-width: 768px) {
  .template-thumbnail-items {
    height: 25vh !important;
  }
  .template-name{
    font-size: .9rem !important;
  }
  .work-container {
    margin: 0rem 1rem;
        padding: 1rem;
  }
  .work-header {
    margin: 0;
  }
  .work-view-more-section button{
    width: 75% !important;
  }
  .template-thumbnails-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    gap: 1rem;
    padding: 1rem 0;
  }
  .work-section div{ 
    background: none !important;
  }
  .slider-container .tabs{
    margin: .8rem .8rem;
    width: 39% !important;
  }
  .work-container div button{
    width: 100%;
  }
  .app-template-thumbnail-items {
    height: 35vh;
  }
}

@media (min-width: 768px) and (max-width: 991.98px){
  .work-container {
    margin: 0rem 2rem;
    padding: 2rem;
  }
  .work-header {
    margin: 0;
  }
  .slider-container .tabs{
    margin: .8rem .8rem;
    width: 30% !important;
  }
  .template-thumbnail-items {
    height: 25vh !important;
  }
  .app-template-thumbnail-items {
    height: 35vh;
  }
}
