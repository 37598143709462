
.support-section {
  height: 100%;
}
.support-container {
  margin: 3rem 12rem;
  padding: 4rem;
}
.support-box {
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(285deg, #01bfc59d 0%, #33d192b1 100%)
    0% 0% no-repeat padding-box !important;
  opacity: 1;
  border-radius: 29px;
  display: grid;
  grid-template-columns: 1.2fr 1fr;
}
.support-image-container .support-image {
  width: 85%;
  height: 123.5%;
  position: relative;
  top: -18%;
  left: 18%;
}


.support-paragraph {
  margin: 0;
}

.support-text {
  padding: 4rem 0rem 4rem 4rem;
}
.support-text .support-heading {
  text-align: left;
  padding-right: 4rem;
}
.support-btn .btn {
  margin: 0 0.3rem;
  width: 30%;
}

@media (max-width: 768px) {
  .support-container {
    margin: 0rem 1rem;
    padding: 1rem;
  }

  .support-text {
    padding: 1.5rem 0 1.5rem 1.5rem;
  }
  .support-text .support-heading {
    padding-right: 0rem;
  }
  .support-box {
    display: flex;
    flex-direction: column-reverse;
  }
  .support-image-container{
    justify-content: center;
    display: flex;
    align-items: center;
    padding-top: 2rem;
  }
  .support-image-container .support-image {
    height: auto;
    top: 0;
    left: 0;
  }
  .support-paragraph {
    margin: 1rem 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .support-container {
    margin: 0rem 2rem;
    padding: 4rem 2rem;
  }
  .support-image-container .support-image {
    width: 86%;
    height: 120%;
    top: -20%;
    left: 6%;
  }
  .support-section {
    height: 75% !important;
  }
  .support-box {
    height: 75% !important;
  }
  .support-text {
    padding: 1.5rem 0 1.5rem 1.5rem;
  }
  .support-text .support-heading {
    padding-right: 0rem;
  }
  .support-btn .btn {
    width: 35%;
  }
  .support-text {
    font-size: 1rem;
  }
  .support-text .support-heading {
    font-size: 1.8rem;
  }
}






















