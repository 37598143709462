.footer-section {
    background:#18B99F;
    height: 100%;
  }
  .footer-container {
    margin: 0rem 12rem;
    padding: 4rem 4rem 2rem 4rem;
  }
  .footer-container-padding {
    margin: 0;
    padding: 4rem 16rem;
  }
  .footer-terms{
    width: 35%;
    justify-content: start !important;
  }
  
  .footer-header {
    margin: 0 10rem;
  }
  .footer-header {
    margin: 0 10rem;
  }
  
  .contact-form-input {
    background-color: #00000000;
  }
  .contact-form-input .form-control {
    background-color: #00000000;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #e9e8e8;
    color: #fff;
  }
  .contact-form-input label {
    color: #fff !important;
  }
  .footer-links-grid{
    display: flex;
    justify-content: space-around;
  }
  
  .contact-form-input .form-control:focus {
    border: none;
    border-bottom: 2px solid #e9e8e8;
    box-shadow: none;
    outline: none;
  }
  .dark-contact-form-input {
    background-color: #00000000;
  }
  .dark-contact-form-input .form-control {
    background-color: #fff;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #000;
    color: #000;
  }
  .dark-contact-form-input label {
    color: #000 !important;
  }
  
  .dark-contact-form-input .form-control:focus {
    border: none;
    border-bottom: 2px solid #000;
    box-shadow: none;
    outline: none;
  }
  .right-margin {
    margin-right: 1rem;
  }
  .left-margin {
    margin-left: 1rem;
  }
  .btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
  }
  .footer-details {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 0;
  }
  .footer-items-contact p,
  h6,
  .footer-items-links p {
    margin-bottom: 0.5rem;
  }
  .footer-items-links p {
    font-size: 0.9rem;
    cursor: pointer;
  }
  .footer-items-links p:hover {
    color: #e9e8e8 !important;
    text-decoration-line: underline;
  }
  .footer-details p {
    font-size: 0.8rem;
  }
  .footer-collab img{
      width: 100%;
    }
  .copyright-text{
    font-size: .9rem;
  }
  @media (max-width: 768px) {
    .footer-details {
      display: block;
    }
    .footer-items-logo img{
      width: 50% !important;
    }
    .footer-items-logo p{
      padding-bottom: 2rem;
    }
    .footer-container {
      margin: 0rem 1rem;
      padding: 1rem;
    }
    .footer-container-padding {
      margin: 0;
      padding: 1rem 2rem;
    }
    .footer-header {
      margin: 0;
    }
    .footer-section div {
      background: none;
    }
    .right-margin {
      margin-right: 0rem;
    }
    .left-margin {
      margin-left: 0rem;
    }
    .footer-details div{
      margin-bottom: 1rem;
    }
  .footer-social{
      flex-direction: column-reverse !important;
      justify-content: center !important;
      align-items: center !important;
  }
  .footer-social div{
      margin-bottom: 1rem;
  }
  .footer-terms{
    width: 100%;
    justify-content: center !important;
  }
  .footer-terms p{
    text-align: center;
  }

  .copyright-text{
    font-size: .7rem;
    text-align: center !important;
  }
  .copyright-div{
    margin-top: 1rem !important;
  }
  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .footer-container {
      margin: 0rem 2rem;
      padding: 2rem;
    }
    .footer-container-padding {
      margin: 0;
      padding: 2rem 4rem;
    }
    .footer-header {
      margin: 0;
    }
    .footer-terms{
      width: 75%;
      justify-content: center !important;
    }
    .footer-terms p{
      text-align: center;
    }
  
  
  
  }
  