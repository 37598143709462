.hero-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: transparent linear-gradient(180deg, #13e1c6 0%, #ad0ee7 100%) 0%
    0% no-repeat padding-box;
  height: 100vh;
  position: relative;
}

.intro-text {
  flex: 0.7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.2rem 1.2rem 1.2rem 8rem;
}

.intro-text h1 {
  font-size: 48px;
  color: #fff;
  margin-bottom: 20px;
  width: 100%;
  text-align: start !important;
}


.intro-text p {
  font-size: 18px;
  color: #fff;
  margin-bottom: 30px;
  text-align: start !important;
}

.projects-button {
  background-color: #fff;
  border: none;
  padding: 10px 40px;
  color: #000;
  cursor: pointer;
  border-radius: 46px;
  margin-top: 20px;
}

.intro-img {
  padding-top: 8rem;
  flex: 0.4;
  text-align: center;
}

.intro-img img {
  height: 100%;
  width: 100%;
}

.scroll-down-icon {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 768px) {
  .hero-container {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: none;
  }
  .intro-img {
    padding-top: 0;
  }
  .intro-text {
    padding: 1.2rem;
  }
  
}

@media (min-width: 768px) and (max-width: 991.98px) {
}
