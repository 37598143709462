.about-us {
    width: 100%;
    max-width:800px;
    margin: 50px auto;
    font-family: Arial, sans-serif;
    padding: 0 15px; 
    text-align: center;
   
}


.about-us p {
    font-size: 16px;
    line-height: 1.5;
    text-align: start;
}
