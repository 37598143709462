



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-gray {
  color: #666666;
}
.text-dark {
  color: #000000;
}
.text-light {
  color: #ffffff;
}
.text-light-gray {
  color: #e9e8e8;
}
.text-heading {
  color: #00b7bd;
}
.bg-light {
  background: #fff !important;
}
.linear-background {
  background: transparent linear-gradient(137deg, #35d18e 0%, #00b7bd 100%) 0%
    0% no-repeat padding-box;
}
.white-background {
  background-color: white;
}
.btn-primary {
  background: transparent linear-gradient(285deg, #35d18e 0%, #00b7bd 100%) 0%
    0% no-repeat padding-box !important;
  color: #ffffff !important;
  border: none;
  border-radius: 10px;
  width: 25%;
  padding: 0.8rem;
  font-size: 0.8rem;
  user-select: none !important; 
}
.btn-primary:hover {
  background: transparent linear-gradient(285deg, #37d390 0%, #01bfc5 100%) 0%
    0% no-repeat padding-box !important;
}
.btn-primary:focus{
  background: transparent linear-gradient(285deg, #35d18e 0%, #00b7bd 100%) 0%
  0% no-repeat padding-box !important;
}



.btn-secondary {
  background: #bb68c8;
  color: #ffffff !important;
  border: none;
  border-radius: 10px;
  width: 25%;
  padding: 0.8rem;
  font-size: 0.8rem;
  user-select: none !important; 
}
.btn-secondary:hover {
  background: #c763d7;
}
.btn-secondary:focus {
  background: #c763d7;
}



.btn-light {
  background: #ffffff;
  color: #00b7bd !important;
  border: none;
  border-radius: 10px;
  width: 25%;
  padding: 0.8rem;
  font-size: 0.8rem;
  user-select: none !important; 
}
.btn-light:hover {
  background: #f5f1f1;
  
}
.btn-light:focus {
  background: #ffffff;
}



.btn-outline-primary {
  border: 2px solid #00b7bd !important;
  color: #00b7bd !important;
  border-radius: 10px;
  width: 25%;
  padding: 0.8rem;
  font-size: 0.8rem;
  user-select: none !important; 
}
.btn-outline-primary:hover {
  color: #ffffff !important;
  border: 2px solid #00b7bd !important;
  background: transparent linear-gradient(285deg, #37d390 0%, #01bfc5 100%) 0%
    0% no-repeat padding-box !important;
}
.btn-outline-primary:focus{
  color: #ffffff !important;
}


.btn-outline-light {
  border: 2px solid #ffffff !important;
  color: #ffffff !important;
  border-radius: 10px;
  width: 25%;
  padding: 0.8rem;
  font-size: 0.8rem;
  user-select: none !important; 
}
.btn-outline-light:hover {
  color: #00b7bd !important;
  border: none !important;
  background: #ffffff;
}
.btn-outline-light:focus {
  color: #00b7bd !important;
}


.btn-dark {
  background: #000000 !important;
  color: #ffffff !important;
  border: none;
  border-radius: 10px;
  width: 25%;
  padding: 0.8rem;
  font-size: 0.8rem;
  user-select: none !important; 
}
.btn-dark:hover {
  background: #141313 !important;
}
.btn-dark:focus {
  background: #000000 !important;
}


.btn-primary:focus,
.btn-secondary:focus,
.btn-dark:focus,
.btn-light:focus{
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}


.btn-outline-primary:focus,
.btn-outline-light:focus {
  outline: none !important;
  box-shadow: none !important;
}

.thas-heading {
  text-align: -webkit-center;
  letter-spacing: 0px;
  font-size: 3.4rem;
  padding: 1rem 0;
}
.thas-subheading {
  letter-spacing: 0px;
  text-align: -webkit-center;
  font-size: 1.1rem;
  line-height: 27px;
}

.dropdown-menu{
  min-width: 0 !important;
}
.dropdown-item{
  padding: .5rem !important;
}
.nav-item.dropdown .dropdown-toggle {
  display: block !important; 
}
.h2-heading-font{
  font-size: 3.5rem !important;
}

@media (max-width: 768px) {
  .btn{
    width: 50%;
  }
  .thas-heading {
    font-size: 2.5rem !important;
  }
}
.green-color{
  background: transparent linear-gradient(285deg, #01bfc59d 0%, #33d192b1 100%)
    0% 0% no-repeat padding-box !important;
}