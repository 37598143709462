.app-template-container {
    display: flex;
    flex-direction: column;
    padding: 0 100px;
}

.app-template-heading{
    font-size: 1rem !important;
}
.app-template-video video{
    width: 25%;
    display: flex;
    justify-content: center;
    margin: auto;
    align-items: center;
    height: auto;
    box-shadow: 0px 2px 40px #00000040;
}
.app-template-video{
    padding: 2rem;
}
.app-template-images{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    margin: auto;
}
.app-template-images img{
    width: 75%;
    height: 85%;
    margin: auto;
    margin-bottom: 2rem;
    box-shadow: 0px 2px 40px #00000030;
}

@media (max-width: 768px) {
    .app-template-images{
        display: block;
    }
    .app-template-container{
        padding: 10px;
    }
    .app-template-heading{
        font-size: .8rem !important;
    }
    .app-template-video{
        padding: 0rem;
    }
    .app-template-video video{
        width: 100%;
        height: auto;
        margin-bottom: 2rem;
    }
    .app-template-images{
        padding: 2rem 2rem;
    }
    .app-template-images img{
        width: 100%;
    }
    
    
}

@media (min-width: 768px) and (max-width: 991.98px){
    .app-template-images{
        padding: 2rem 2rem;
    }
    .app-template-images img{
        width: 100%;
    }
    
}