.main-container {
  max-width: 1200px;
  margin: 80px auto 0 auto;
  color: #545454;
  padding: 10px;
}

.main-container h1 {
  font-size: 22px;
}

.main-container h4 {
  font-size: 16px;
}

.primary-color {
  background-color: #f2d300;
  color: #fff;
}

.template-details {
  display: flex;
  justify-content: center;
}

.template-details-left {
  flex: 1;
  padding: 20px;
  max-width: 60%;
}

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 10px;
  border: 2px solid #ecf1f4;
  border-radius: 5px;
  margin-bottom: 10px;
}

button {
  padding: 10px;
  margin: 10px 5px 0 5px;
  border-radius: 5px;
  transition-duration: 300ms;
}

.image-container button:hover {
  background-color: #82b440 !important;
  color: #fff;
}

.template-details-right {
  flex: 0.5;
  padding: 20px;
}

.template-details-right div {
  border: 2px solid #ecf1f4;
  border-radius: 5px;
  padding: 50px;
}

.add-to-cart {
  background-color: #82b440;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
}

.add-to-cart:hover {
  background-color: #f2d300;
  color: #000;
}

@media (max-width: 768px) {
  .template-details {
    flex-direction: column;
  }

  .template-details-left,
  .template-details-right {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .template-details h1 {
    font-size: 24px;
  }

  .template-details h3 {
    font-size: 18px;
  }
  .template-details-left {
    min-width: 100%;
  }
}
