.nav-item{
    background-color: 'red';
}
.nav-link{
    color: black;
    margin-right: 20px;
    font-weight: bold;
}
.nav-link:hover, .nav-link:active, .nav-link:focus{
    color: #AC10E7;
}
.nav-link.active{
    color: #AC10E7 !important;
}
.nav-item.dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
}
.navbar-toggler{
    color: #AC10E7 !important;
    width: 20%;
}
.navbar-toggler i{
    font-size: 1.6rem !important;
}
.navbar-toggler:active, .navbar-toggler:focus, .navbar-toggler:hover{
    background-color: #fff !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: #6f0d93 !important;
}
.dropdown-menu {
    border: none;
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
}

.dropdown-item {
    display: flex;
    align-items: center;
}

.dropdown-item img {
    margin-right: 10px;
}
